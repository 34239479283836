<template>
  <div v-html="content">{{ content }}</div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "TermsAndConditions",
  data() {
    return {
      content: {}
    };
  },
  methods: {
    ...mapActions("page", ["fetchPageDetailsBySlug"])
  },
  mounted() {
    this.fetchPageDetailsBySlug(this.$route.params.slug).then(res => {
      this.content = res.data;
      let slug = this.$route.params.slug;
      slug = slug.split("-").join(" ");
      let newSlug = slug.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
      document.title = "Fresh Clinics | " + newSlug;
    });
  }
};
</script>
